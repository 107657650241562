<template>
  <v-menu
    v-model="showModal"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :prepend-icon="icons.mdiCalendar"
        :label="label"
        :outlined="outlined"
        hide-details="auto"
        v-bind="attrs"
        v-on="on"
        :required="required"
        :rules="validationRules"
        readonly
        dense
      />
    </template>
    <v-date-picker v-model="date" no-title :color="color" @input="onInput" />
  </v-menu>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import moment from 'moment-timezone'
import AcInput from './AcInput.vue'

export default {
  name: 'AcDatePicker',
  components: { AcInput },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    format: {
      type: String,
      default: 'DD MMM YYYY',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      date: null,
      showModal: false,
      icons: {
        mdiCalendar,
      },
    }
  },
  computed: {
    formattedDate() {
      return this.date ? moment(this.date).format(this.format) : null
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.date = moment(val).format('YYYY-MM-DD')
        } else {
          this.date = null
        }
      },
    },
  },
  methods: {
    onInput(val) {
      this.showModal = false
      this.$emit('input', val)
    },
  },
}
</script>
