<template>
  <v-radio-group v-model="selectedRadio" @change="onChange" class="mt-0" hide-details>
    <div :class="[{ 'd-flex align-center flex-wrap': !vertical }]">
      <v-radio
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :color="item.color || color"
        :value="item.value"
        class="pe-3"
      />
    </div>
  </v-radio-group>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AcRadioGroup',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: 'primary',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedRadio = ref(props.value)

    const onChange = val => {
      emit('input', val)
    }

    watch(
      () => props.value,
      val => (selectedRadio.value = val),
    )

    return {
      selectedRadio,
      onChange,
    }
  },
}
</script>

<style lang="scss" scoped></style>
