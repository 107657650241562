import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useRestricted() {
  const restrictedList = ref([])
  const totalRestrictedList = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'Security', value: 'security' },
    { text: 'List Type', value: 'list-type' },
    { text: 'Start Date', value: 'start' },
    { text: 'Expiry Date', value: 'expiry' },
    { text: 'Type', value: 'type' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchRestrictedList = () => {
    store
      .dispatch('pa-dealing/fetchRestrictedList', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        restrictedList.value = data.docs
        totalRestrictedList.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchRestrictedList()
  })

  return {
    headers,
    options,
    restrictedList,
    totalRestrictedList,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
  }
}
