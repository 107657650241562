<template>
  <v-dialog v-model="isDialogVisible" persistent max-width="600px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="white--text">Add New Entry</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <ac-radio-group :items="listTypes" v-model="selectedListType" />
            </v-col>
            <v-col cols="12">
              <ac-input label="Security" />
            </v-col>
            <v-col cols="12">
              <ac-radio-group :items="restrictTypes" v-model="selectedRestrictType" />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-input label="Ticker Symbol or Stock Code" />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-input label="Exchange" />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-date-picker label="Start Date" />
            </v-col>
            <v-col cols="12" sm="6">
              <ac-date-picker label="Expiry Date" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
        <ac-button title="Save" color="success" @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcDatePicker from '@/components/AcDatePicker.vue'
import AcInput from '@/components/AcInput.vue'
import AcRadioGroup from '@/components/AcRadioGroup.vue'
import { ref, watch } from '@vue/composition-api'

export default {
  name: 'AddRestrictedEntryModal',
  components: { AcInput, AcRadioGroup, AcButton, AcDatePicker },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const listTypes = [
      { label: 'Restricted', value: 'restricted' },
      { label: 'Watch List', value: 'watch-list' },
    ]

    const restrictTypes = [
      { label: 'Public', value: 'public' },
      { label: 'Private', value: 'private' },
    ]

    const selectedListType = ref('restricted')
    const selectedRestrictType = ref('public')
    const isDialogVisible = ref(props.value)

    const onClose = () => {
      emit('input', false)
    }

    watch(
      () => props.value,
      val => (isDialogVisible.value = val),
    )

    return {
      onClose,
      isDialogVisible,
      listTypes,
      restrictTypes,
      selectedListType,
      selectedRestrictType,
    }
  },
}
</script>

<style lang="scss" scoped></style>
