<template>
  <div>
    <ac-module-heading> Restricted List </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-select
            label="Company"
            v-model="selectedCompany"
            :items="companies"
            class="company-select me-3"
          />
          <ac-button title="Add Entry" :icon="icons.mdiPlus" @click="isDialogVisible = true" />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-button title="Download CSV" :icon="icons.mdiDownload" class="me-3" />
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="restrictedList"
        :options.sync="options"
        :server-items-length="totalRestrictedList"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.list-type`]="{ item: { listType } }">
          <span class="font-weight-medium">{{ listType }}</span>
        </template>

        <template #[`item.start`]="{ item: { startDate } }">
          <span>{{ startDate }}</span>
        </template>

        <template #[`item.expiry`]="{ item: { expiryDate } }">
          <span>{{ expiryDate }}</span>
        </template>

        <template #[`item.actions`]>
          <ac-icon-btn text="Delete" color="error" :icon="icons.mdiDeleteOutline" />
        </template>
      </v-data-table>
      <add-restricted-entry-modal v-model="isDialogVisible" />
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import AddRestrictedEntryModal from '@/components/PaDealing/AddRestrictedEntryModal.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import { mdiDeleteOutline, mdiDownload, mdiPlus } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useRestricted from './composition-apis/restricted'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcIconBtn,
    AddRestrictedEntryModal,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      headers,
      options,
      restrictedList,
      totalRestrictedList,
      isDialogVisible,
      searchQuery,
      companies,
      selectedCompany,
      loading,
    } = useRestricted()

    return {
      headers,
      options,
      restrictedList,
      totalRestrictedList,
      isDialogVisible,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      icons: { mdiDeleteOutline, mdiPlus, mdiDownload },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
